<template>
	<main class="report-main" v-if="!animation">

		<router-link :to="{ name: 'Reports' }">← Назад</router-link><br><br>
		<h1>Отчёт о вебинаре</h1>
		<p>по теме: «{{ getWebinar.title }}»</p>
		<dl>
			<div>
				<dt>Дата проведения:</dt>
				<dd>{{ normalizeDate(getWebinar.started) }}, {{ normalizeTime(getWebinar.started) }} мск</dd>
			</div>
			<div v-if="getWebinar.speakers != 'Докладчики не определены'">
				<dt>Докладчики:</dt>
				<dd v-for="speaker, key in getWebinar.speakers"
					:key="'speaker-'+key"
				>
					{{ speaker.name + ' ' + speaker.surname + ', ' + (speaker.organization ? speaker.organization : 'Организация не указана') }}
				</dd>
			</div>
			<div v-else><p>Докладчики не определены</p></div>
		</dl>
		<section class="report-table-wrapper report-table-wrapper__pivot">
			<h2>Статистика заявок по регионам:</h2><br>
			<table>
				<thead>
					<tr>
						<td>Субъект РФ</td>
						<td>Заявок</td>
						<td>Участвовало</td>
						<td>% от участия</td>
					</tr>
					<tr>
						<td><b>Регионов записалось: {{ uniqueRegions.length }}</b></td>
						<td><b>{{ getVisitorsStats.length }}</b></td>
						<td><b>{{ visitedCount }}</b></td>
						<td><b>{{ percentOfVisitors(getVisitorsStats.length, visitedCount) }}%</b></td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="tdata, rkey in pivotTable" :key="'region-'+rkey">
						<td>{{ tdata.subject }}</td>
						<td>
							{{ tdata.requests }}
						</td>
						<td>
							{{ tdata.visitors }}
						</td>
						<td>
							{{ tdata.percentage }}%
						</td>
					</tr>
				</tbody>
			</table>
		</section>
		<article class="report-info">
			<p><strong>Подали заявки {{ getVisitorsStats.length }} человек из {{ uniqueRegions.length }} {{uniqueRegions.length%10 == 1 ? 'региона' : 'регионов'}}, не подавали заявки {{ notRegisteredRegions.length }} {{notRegisteredRegions.length%10 == 1 ? 'региона' : 'регионов'}}:</strong></p>
			<ul class="regions-list">
				<li v-for="region, urkey in notRegisteredRegions" :key="'not_registered_region-'+urkey">{{ region }}</li>
			</ul>
			<br>
			<p><strong>Участвовали {{ visitedCount - rdnVisitorsCount }} человек из {{ visitedRegions.length }} {{visitedRegions.length%10 == 1 ? 'региона' : 'регионов'}}, не приняли участие {{ notVisitedRegions.length }} {{notVisitedRegions.length%10 == 1 ? 'региона' : 'регионов'}}:</strong></p>
			<ul class="regions-list">
				<li v-for="region, nvrkey in notVisitedRegions" :key="'not_visited_region-'+nvrkey">{{ region }}</li>
			</ul>

		</article>
		<p v-if="rdnVisitorsCount > 0">Дополнительно, от ФАУ "РОСДОРНИИ" приняли участие - {{ rdnVisitorsCount }} человек.</p>
		<section v-if="getVisitorsStats.length > 0" class="report-table-wrapper report-table-wrapper__listeners">
			<h2>Перечень заявок:</h2><br>
			<table>
				<thead>
					<tr>
						<td>Субъект РФ</td>
						<td>ФИО</td>
						<td>Организация</td>
						<td>Должность</td>
						<td>Участие</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="visitor, key in notRdnVisitiors" :key="'visitor-'+key">
						<td>{{ visitor.subject }}</td>
						<td>{{ visitor.fullName }}</td>
						<td>{{ visitor.organization }}</td>
						<td>{{ visitor.position }}</td>
						<td>{{ visitor.visited }}</td>
					</tr>
				</tbody>
			</table>
		</section>
		<article v-if="getWebinarQuestionsReport.length > 0">
			<h2>Вопросы из чата вебинара:</h2>
			<ul v-if="getWebinarQuestionsReport && getWebinarQuestionsReport.length > 0">
				<li v-for="question, qkey in getWebinarQuestionsReport" :key="'question-'+qkey">{{ question.text }}</li>
			</ul>
		</article>
		<br>
		<div class="download-btn-wrapper">
			<button type="button" class="btn__blue" @click="exportDocx">Скачать .docx</button>
		</div>
	</main>
	<main class="vcc-main main__loading" v-else>
		<transition name="fade-in">
			<book/>
		</transition>
	</main>
</template>

<script>
import { Document, Paragraph, Packer, HeadingLevel, SectionType, TextRun, TableRow, TableCell, Table, AlignmentType } from "docx";
import { saveAs } from 'file-saver';
import { mapActions, mapGetters } from 'vuex'
import book from '@/components/animations/book.vue';
export default {
  components: { book },
	data() {
		return {
			data: null,
			animation: false
		}
	},
	props: ['id'],
	computed: {
		...mapGetters(['getVisitorsStats', 'getWebinar', 'getRfSubjects', 'getWebinarQuestionsReport']),
		title() {
			return this.getWebinar.title
		},
		pivotTableNoRdn() {
			return this.getRfSubjects.map((subject, index) => {
				return {
					index: index,
					subject: subject,
					requests: this.countOfRequests(subject) - this.countOfRdnRequests(subject),
					visitors: this.countOfVisitors(subject) - this.countOfRdnVisitors(subject),
					percentage: this.percentOfVisitors((this.countOfRequests(subject) - this.countOfRdnRequests(subject)), (this.countOfVisitors(subject) - this.countOfRdnVisitors(subject)))
				}
			})
		},
		pivotTableWithRdn() {
			return this.getRfSubjects.map((subject, index) => {
				if(this.countOfRdnRequests(subject) != 0) {
					return {
						index: index,
						subject: subject + " (ФАУ 'РОСДОРНИИ')",
						requests: this.countOfRdnRequests(subject),
						visitors: this.countOfRdnVisitors(subject),
						percentage: this.percentOfVisitors(this.countOfRdnRequests(subject), this.countOfRdnVisitors(subject))
					}
				} else {
					return false
				}
			})
			.filter((data) => data != false)
		},
		pivotTable() {
			const table = [
					...this.pivotTableNoRdn,
					...this.pivotTableWithRdn,
				]
			return table.sort((a, b) => a.index - b.index)
		},
		visitedCount() {
			return this.getVisitorsStats.filter((visiter) => {
				return visiter.visited == "да" ? true : false
			}).length
		},
		notVisitedCount() {
			return this.getVisitorsStats.filter((visiter) => {
				return visiter.visited == "нет" ? true : false
			}).length
		},
		uniqueRegions() {
			return this.getVisitorsStats
				.map(visitor => { return visitor.subject })
				.filter(this.onlyUnique)
		},
		visitedRegions() {
			let subjects = []
			this.getVisitorsStats.map((visitor) => {
				visitor.visited == 'да' ? subjects.push(visitor.subject) : false
			})
			return subjects.filter(this.onlyUnique)
		},
		notRegisteredRegions() {
			let subjects = this.getRfSubjects
			for(let i = 0; i < this.uniqueRegions.length; i++) {
				subjects = subjects.filter(subject => subject !== this.uniqueRegions[i])
			}
			return subjects
		},
		notVisitedRegions() {
			let subjects = [...this.getRfSubjects]
			for(let i = 0; i < this.visitedRegions.length; i++) {
				subjects = subjects.filter(subject => subject !== this.visitedRegions[i])
			}
			return subjects
		},
		rdnVisitorsCount() {
			return this.getVisitorsStats.filter(visitor => {
				return visitor.organization.toLowerCase().search('росдорнии') >= 0 ? true : false
			})
			.filter(visitor => {
				return visitor.visited == 'да' ? true : false
			})
			.length
		},
		notRdnVisitiors() {
			return this.getVisitorsStats.filter((visitor) => {
				return visitor.organization.toLowerCase().search('росдорнии') >= 0 ? false : true
			})
			.sort((a, b) => a.subject.localeCompare(b.subject))
		},
		rdnVisitiors() {
			return this.getVisitorsStats.filter((visitor) => {
				return visitor.organization.toLowerCase().search('росдорнии') >= 0 ? true : false
			})
		},

		/*
			##########################
			# DOCX EXPORT PROPERTIES #
			##########################
		*/
		speakersList() {
			const speakers = this.getWebinar.speakers
			if(speakers.length != 0 && Array.isArray(speakers)) {
				let exported_speakers = []
				for(let i in speakers) {
					exported_speakers.push(
						new TextRun({
							text: speakers[i].name + ' ' + speakers[i].surname + ', ' + speakers[i].organization,
							break: 1
						})
					)
				}
				return exported_speakers
			} else {
				return [new TextRun({
							text: 'Докладчики не определены'
						})]
			}
		},

		visitorsStatsTable() {
			const visitors = this.notRdnVisitiors
			let exported_visitors = []
			const tableRowHeader = new TableRow({
				tableHeader: true,
				children: [
					new TableCell({
						children: [ new Paragraph({ text:"Субъект РФ" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"ФИО" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"Организация" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"Должность" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"Участие" }) ]
					}),
				]
			})

			exported_visitors.push(tableRowHeader)

			for(let i in visitors) {
				exported_visitors.push(
					new TableRow({
						children: [
							new TableCell({
								children: [	new Paragraph({ text: visitors[i].subject }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: visitors[i].fullName }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: visitors[i].organization }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: visitors[i].position }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: visitors[i].visited }) ]
							}),
						]
					})
				)
			}
			return exported_visitors
		},
		regionStatsTable() {
			let exported_regions = []
			const tableRowHeader = new TableRow({
				tableHeader: true,
				children: [
					new TableCell({
						children: [ new Paragraph({ text:"Субъект РФ" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"Заявок" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"Участвовало" }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text:"% от участия" }) ]
					})
				]
			})

			const tableRowSubHeader = new TableRow({
				children: [
					new TableCell({
						children: [ new Paragraph({ text:"Регионов записалось" + this.uniqueRegions.length }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text: ""+this.getVisitorsStats.length }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text: ""+this.visitedCount }) ]
					}),
					new TableCell({
						children: [ new Paragraph({ text: ""+this.percentOfVisitors(this.getVisitorsStats.length, this.visitedCount) }) ]
					})
				]
			})

			exported_regions.push(tableRowHeader)
			exported_regions.push(tableRowSubHeader)

			for(let i in this.pivotTable) {
				exported_regions.push(
					new TableRow({
						children: [
							new TableCell({
								children: [	new Paragraph({ text: ""+this.pivotTable[i].subject }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: ""+this.pivotTable[i].requests }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: ""+this.pivotTable[i].visitors }) ]
							}),
							new TableCell({
								children: [	new Paragraph({ text: ""+this.pivotTable[i].percentage + "%" }) ]
							})
						]
					})
				)
			}
			return exported_regions
		},
		textRunNotRegisteredRegions() {
			const array_size = this.notRegisteredRegions.length
			return this.notRegisteredRegions.map((region, index) => {
				if(index + 1 < array_size) {
					return new TextRun({
						text: region + ", ",
					})
				} else {
					return new TextRun({
						text: region + ".",
					})
				}
			})
		},
		textRunNotVisitedRegions() {
			const array_size = this.notVisitedRegions.length
			return this.notVisitedRegions.map((region, index) => {
				if(index + 1 < array_size) {
					return new TextRun({
						text: region + ", ",
					})
				} else {
					return new TextRun({
						text: region + ".",
					})
				}
			})
		},
		webinarQuestions() {
			const questions = this.getWebinarQuestionsReport
			let formatted_questions = []
			if(questions.length > 0) {
				for(let i in questions) {
					formatted_questions.push(
						new Paragraph({
							text: questions[i],
							bullet: {
								level: 0
							}
						})
					)
				}
			} else {
				formatted_questions = [
					new TextRun({
						text: 'Во время проведения вебинара, вопросов не было.'
					})
				]
			}
			return formatted_questions
		}
	},
	// watch: {
	// 	title(oldValue, newValue) {
	// 		if(newValue || oldValue) {
	// 			this.animation = false
	// 		} else {
	// 			this.animation = false
	// 		}
	// 	}
	// },
	methods: {
		...mapActions(['fetchEventSessionParticipations', 'fetchWebinarFromWebinarRu', 'fetchWebinarQuestions']),
		onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		},
		countOfRequests(region) {
			let requests = 0
			this.getVisitorsStats.forEach(visitor => {
				if(visitor.subject == region) requests++
			})
			return requests
		},
		countOfRdnRequests(region) {
			let requests = 0
			this.rdnVisitiors.forEach(visitor => {
				if(visitor.subject == region) requests++
			})
			return requests
		},
		countOfVisitors(region) {
			let visitors = 0
			this.getVisitorsStats.forEach(visitor => {
				if(visitor.subject == region) {
					if(visitor.visited == 'да') visitors++
				}
			})
			return visitors
		},
		countOfRdnVisitors(region) {
			let visitors = 0
			this.rdnVisitiors.forEach(visitor => {
				if(visitor.subject == region) {
					if(visitor.visited == 'да') visitors++
				}
			})
			return visitors
		},
		percentOfVisitors(registered, visitors) {
			if(visitors === 0) return 0
			const c = Number(registered)/Number(visitors)
			return Math.floor(100/c)
		},
		exportDocx() {
			// Create a new Document an save it in a variable
			const doc = new Document({
				sections: [{
					properties: {
						type: SectionType.CONTINUOUS,
					},
					children: [
						new Paragraph({
							text: "Отчет о вебинаре",
							heading: HeadingLevel.TITLE,
							alignment: AlignmentType.CENTER,
						}),
						new Paragraph({
							text: "по теме: «" + this.getWebinar.title + "»",
							alignment: AlignmentType.CENTER,
						}),
						new Paragraph({
							text: "Дата проведения: " +
								this.normalizeDate(this.getWebinar.started) +
								", " +
								this.normalizeTime(this.getWebinar.started) +
								" мск.",
							spacing: { before: 200 }
						}),
						new Paragraph({
							text: "Докладчики:",
							spacing: { before: 200 },
							children: this.speakersList
						}),
						new Paragraph({
							text: "Статистика заявок по регионам:",
							spacing: { before: 200, after: 200 }
						}),
						new Table({
							columnWidths: [6000, 5505, 3505, 3505],
							rows: this.regionStatsTable
						}),
						new Paragraph({
							text: "Подали заявки " + this.getVisitorsStats.length + " человек из " + this.uniqueRegions.length  + (this.uniqueRegions.length%10 == 1 ? ' региона' : ' регионов') + ", не подавали заявки " + this.notRegisteredRegions.length + (this.notRegisteredRegions.length%10 == 1 ? ' регион' : ' регионов')+ ": ",
							spacing: { before: 200, after: 200 },
							children: this.textRunNotRegisteredRegions
						}),
						new Paragraph({
							text: "Участвовали " + (this.visitedCount - this.rdnVisitorsCount) + " человек из " + this.visitedRegions.length  + (this.visitedRegions.length%10 == 1 ? ' региона' : ' регионов') + ", не приняли участие " + this.notVisitedRegions.length + (this.notVisitedRegions.length%10 == 1 ? ' регион' : ' регионов')+ ": ",
							spacing: { before: 200, after: 200 },
							children: this.textRunNotVisitedRegions
						}),
						new Paragraph({
							text: "Дополнительно, от ФАУ 'РОСДОРНИИ' приняли участие - "+ this.rdnVisitorsCount + " человек.",
							spacing: { before: 200, after: 200 },
						}),
						new Table({
							columnWidths: [4000, 6000, 3000, 2000, 1000],
							rows: this.visitorsStatsTable
						}),
						new Paragraph({
							text: "Вопросы в чате: ",
							spacing: { before: 200 },
						}),
						new Paragraph({
							text: " ",
							spacing: { before: 200 },
							children: this.webinarQuestions
						})
					]
				}],
			});
			const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
			const fileName = "Отчет о вебинаре " + this.normalizeDate(this.getWebinar.started) + ".docx";
			Packer.toBlob(doc).then((blob) => {
				const docblob = blob.slice(0, blob.size, mimeType);
				saveAs(docblob, fileName);
			});
		},
		toggleAnimation() {
			return this.animation ? this.animation = false : this.animation = true
		}
	},
	created() {
		this.toggleAnimation()
		this.fetchEventSessionParticipations(this.id)
		this.fetchWebinarFromWebinarRu(this.id).then(data => {
			this.toggleAnimation()
			return data
		})
		setTimeout(this.fetchWebinarQuestions(this.id), 500)
	},
}
</script>

<style scoped>
	[v-cloak] {
		display: none;
	}
	main {
		max-width: 1060px;
		padding: 0 10px;
		margin: 30px auto;
		position: relative;
	}

	/* Inline list */
	.regions-list {
		list-style: none;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 0; margin: 0;
	}

	.regions-list li {
		width: max-content;
	}

	.regions-list li:not(:last-child)::after {
		content: ', ';
		display: inline-block;
		margin-right: 5px;
	}
	.regions-list li:last-child::after {
		content: '.';
		display: inline-block;
	}

	/* table */
	table {
		border-collapse: collapse;
	}

	table td {
		border: 1px solid black;
		padding: 3px;
	}

	/* BUTTON */
	.download-btn-wrapper {
		position: fixed;
		bottom: 10px;
		right: 10px;
	}

	.main__loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50vh;
		width: 100vw;
	}
</style>
